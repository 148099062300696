/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  body {
    display: block;
    font-size: 11pt;
    line-height: 13pt;
    min-width: 375px;
  }

  #pageHeader {
    font-size: 14pt;
    line-height: 14pt;
  }

  #pageHeader .logoBlock {
    padding-bottom: 0;
  }

  #pageHeader .toggleMenu {
    display: block;
    margin: 10px;
    font-size: 16pt;
    text-transform: uppercase;
  }

  #pageHeader .contacts {
    display: none;
  }

  #pageHeader nav {
    display: none;
  }

  #pageHeader nav > ul {
    display: block;
    background-color: #E6DBD0;
  }

  #pageHeader nav ul li {
    text-align: left;
    padding: 10px 0;
  }

  #pageHeader nav ul li a {
    display: inline;
    color: #5D125B;
    text-decoration: underline;
    font-weight: bold;
  }

  #pageHeader nav ul li.active a {
    text-decoration: none;
    color: #230029;
    background-color: transparent;
  }

  #pageHeader nav ul li:hover a {
    color: #230029;
    text-decoration: none;
  }

  #pageHeader nav ul li:first-child {
    margin-left: 0;
  }

  #pageHeader nav ul li:last-child {
    margin-right: 0;
  }

  #pageHeader nav > ul > li > ul {
    position: relative;
    border-top-width: 1px;
  }

  #pageHeader nav > ul > li > ul li {
    padding: 0;
  }

  #mainSection {
    display: flex;
    flex-direction: column;
  }

  #sideLeft nav ul,
  #sideLeft nav ul a{
    font-size: 18px !important;
  }

  .last-news {
    font-size: 18px !important;
  }

  #sideRight {
    display: flex;
    flex-direction: column;
  }

  #sideRight  .widget,
  #sideRight  .widget h5{
    margin-top: 0;
  }

  #sideLeft nav {
    padding: 5px;
  }

  #sideLeft nav ul,
  #sideLeft nav ul a{
    font-size: 24px !important;
  }

  #mainArticle .news-item .title {
    font-size: 16pt;
    line-height: 18pt;
  }
}

/*Medium devices (tablets, less than 992px)*/
@media (min-width: 767.99px) and (max-width: 991.98px) {
  body {
    font-size: 11pt;
    line-height: 13pt;
  }

  #pageHeader {
    font-size: 12pt;
    line-height: 14pt;
  }

  #pageHeader .logoBlock {
    padding-bottom: 0;
  }

  #pageHeader .toggleMenu {
    display: block;
    margin: 10px;
  }

  #pageHeader .contacts {
    display: none;
  }

  #pageHeader nav {
    display: none;
  }

  #pageHeader nav > ul {
    display: block;
    background-color: #E6DBD0;
  }

  #pageHeader nav ul li {
    text-align: left;
    padding: 10px 0;
  }

  #pageHeader nav ul li a {
    display: inline;
    color: #5D125B;
    text-decoration: underline;
    font-weight: bold;
  }

  #pageHeader nav ul li.active a {
    text-decoration: none;
    color: #230029;
    background-color: transparent;
  }

  #pageHeader nav ul li:hover a {
    color: #230029;
    text-decoration: none;
  }

  #pageHeader nav ul li:first-child {
    margin-left: 0;
  }

  #pageHeader nav ul li:last-child {
    margin-right: 0;
  }

  #pageHeader nav > ul > li > ul {
    position: relative;
    border-top-width: 1px;
  }

  #pageHeader nav > ul > li > ul li {
    padding: 0;
  }

  #mainSection {
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
    "left article article"
    "right right right";
  }

  #sideLeft nav ul,
  #sideLeft nav ul a{
    font-size: 18px !important;
  }

  .last-news {
    font-size: 16px !important;
  }

  #sideRight {
    display: flex;
    flex-direction: row;
  }

  #sideRight  .widget,
  #sideRight  .widget h5{
    margin-top: 0;
  }

  #sideLeft nav {
    padding: 5px;
  }

  #sideLeft nav ul,
  #sideLeft nav ul a{
    font-size: 16px !important;
  }
}

/*Large devices (desktops, less than 1200px)*/
@media (min-width: 991.99px) and (max-width: 1199.98px) {
  body {
    font-size: 11pt;
    line-height: 13pt;
  }

  #pageHeader,
  #pageHeader nav > ul > li > ul a{
    font-size: 10pt;
    line-height: 12pt;
  }

  #pageHeader .contacts {
    display: none;
  }

  #pageHeader nav ul {
    padding: 0;
    gap: 10px;
  }

  #mainSection {
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-areas:
    "left article article"
    "right right right";
  }

  #sideLeft nav ul,
  #sideLeft nav ul a{
    font-size: 18px !important;
  }

  .last-news {
    font-size: 16px !important;
  }

  #sideRight {
    display: flex;
    flex-direction: row;
  }

  #sideRight  .widget,
  #sideRight  .widget h5{
    margin-top: 0;
  }

  #sideLeft nav {
    padding: 5px;
  }

  #sideLeft nav ul,
  #sideLeft nav ul a{
    font-size: 16px !important;
  }
}

/*Extra Large devices (desktops, less than 1600px)*/
@media (min-width: 1199.99px) and (max-width: 1599.98px) {
  body {
    font-size: 11pt;
    line-height: 13pt;
  }

  #pageHeader {
    font-size: 12pt;
    line-height: 14pt;
  }

  #pageHeader .contacts .container ul,
  #pageHeader .contacts .container .search{
    gap: 15px;
  }

  #pageHeader .contacts .container ul {
    width: 80%;
  }

  #pageHeader .contacts .container .search {
    width: 20%;
  }

  #sideLeft nav ul,
  #sideLeft nav ul a{
    font-size: 18px !important;
  }

  .last-news {
    font-size: 16px !important;
  }

}
